import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTheme } from 'react-jss'
import { CurrencyFormat, Link } from '../../../../ui'
import { useLink } from '../../../../hook'
import useStyles from './row-products-top-style'
import combineClassNames from '../../../../helpers/combineClassNames'
import placeholderImage from '../../../../assets/images/icon_image_placeholder.png'

const RowProductsTopView = ({
  products, isSticky,
}) => {
  const styles = useStyles()
  const { navigate } = useLink()
  const [isInTop, setIsInTop] = useState(false)
  useEffect(() => {
    if (isSticky) {
      setTimeout(setIsInTop(true), 400)
    } else {
      setIsInTop(false)
    }
  }, [isSticky])

  const theme = useTheme()
  const placeholder = _.get(theme, 'config.placeholderImage', placeholderImage)
  function handleClick(e, productDetailUrl) {
    e.preventDefault()
    // when browser support javascript, use navigate function instead
    // then can handle external tracking in onClick callback
    navigate(productDetailUrl)

    // TODO: trigger event for tracking
  }
  return (
    <div className={combineClassNames([styles.container, isInTop ? styles.onTop : ''])}>
      { _.map(products, (sku, idx) => {
        const imageUrl = _.get(sku, 'colorOption.images.0.versions.webMedium')
          || _.get(sku, 'colorOption.defaultImage.versions.webMedium')
        const title = _.get(sku, 'product.title')
        const sellPrice = _.get(sku, 'colorOption.sellPrice')
        const originalPrice = _.get(sku, 'colorOption.originalPrice')
        const productDetailUrl = _.get(sku, 'product.canonicalHref')
        const onSale = originalPrice !== sellPrice
        return (
          <div
            key={`product-mini-card-${idx}`}
            className={styles.productCards}
          >
            {
            sku.id && (
              <Link
                gatsbyLink
                to={productDetailUrl}
                onClick={(e) => handleClick(e, productDetailUrl)}
                className={styles.link}
              >
                <div
                  className={
                    combineClassNames(
                      [styles.image,
                        _.isEmpty(imageUrl) ? styles.noImage : '',
                      ],
                    )
                  }
                >
                  <img
                    src={_.isEmpty(imageUrl) ? placeholder : imageUrl}
                    alt={title}
                  />
                </div>
                <div className={styles.content}>
                  <Dotdotdot clamp={4}>
                    <p>{title}</p>
                  </Dotdotdot>
                  <p className={styles.price}>
                    { onSale && (
                    <CurrencyFormat
                      className={styles.originalPrice}
                      value={originalPrice}
                    />
                    )}
                    <CurrencyFormat
                      className={
                      combineClassNames([styles.sellPrice, onSale && styles.sellPriceOnSale])
                    }
                      value={sellPrice}
                    />
                  </p>
                </div>
              </Link>
            )
          }
          </div>
        )
      })}

    </div>
  )
}

export default RowProductsTopView
